body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #000000;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#root {
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
}

a {
  color: #ffffff;
  text-decoration: none;
  opacity: 1;
  transition: all 0.1s ease-in-out;
}

a:hover {
  color: #ffffff;
  opacity: 1;
  text-shadow: 1px 1px rgba(255, 255, 255, 0.2);
}
