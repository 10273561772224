.container {
  display: flex;
  position: absolute;
  flex-direction: column;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
}

.logo-holder {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  gap: 4rem;
}

.logo-holder svg {
  margin-top: 4rem;
}

.footer,
.header {
  padding: 50px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

@media (max-width: 500px) {
  .footer,
  .header {
    padding-left: 30px;
    padding-right: 30px;
    padding-bottom: 50px;
    padding-top: 50px;
  }
}

.description {
  color: #ffffff;
  font-size: 16px;
  margin-top: 10px;
  -webkit-font-smoothing: antialiased;
  opacity: 0.8;
  letter-spacing: 0.01em;
  font-weight: 400;
  user-select: none;
}

.logo-and-links {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  width: 100%;
  max-width: 900px;
  margin: 0 auto;
  padding: 0 30px;
}

.footer-left {
  margin-top: 5px;
  display: flex;
  flex-direction: column;
}

.footer-right {
  width: 30%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.legal {
  line-height: 1.5rem;
  padding: 0 30px;
  background: #fff;
  color: rgb(35, 34, 34);
}

.legal a {
  color: #000;
  border-bottom: 1px solid #ddd;
}

.legal main {
  width: 100%;
  margin: 0 auto;
  padding: 30px 0;
  max-width: 900px;
}

.legal h1 {
  text-align: center;
}

.license {
  line-height: 1.5rem;
  padding: 0 30px;
  background: #fff;
  color: rgb(35, 34, 34);
}

.license a {
  color: #000;
  border-bottom: 1px solid #ddd;
}

.license main {
  width: 100%;
  margin: 0 auto;
  padding: 30px 0;
  max-width: 900px;
}

.license h1 {
  text-align: center;
}