.logo-outer-container {
  display: flex;
  align-items: flex-end;
  height: 403px;
  width: 448px;
  user-select: none;
  cursor: pointer;
}

.logo-container {
  width: 50%;
  margin-left: 50%;
  background-color: #ffffff;
  animation-name: logo-up;
  animation-duration: 2s;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
  transform: rotateZ(0deg);
  position: relative;
}

.logo-cutout-left {
  margin-bottom: 18.11414392%;
  height: 27.54342432%;
  width: 0px;
  background-color: #ffffff;
  position: absolute;
  left: 0px;
  right: 0px;
  bottom: 0px;
  margin-left: 0px;
  animation-name: cutout-left-move;
  animation-duration: 2s;
  animation-delay: 2s;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
}

.logo-cutout-right {
  margin-bottom: 18.11414392%;
  height: 27.54342432%;
  width: 0px;
  background-color: #000000;
  position: absolute;

  right: 0px;
  bottom: 0px;

  animation-name: cutout-right-move;
  animation-duration: 2s;
  animation-delay: 2s;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
}

@keyframes cutout-left-move {
  0% {
    width: 0px;
    margin-left: 0px;
  }
  100% {
    width: 100%;
    margin-left: -100%;
  }
}

@keyframes cutout-right-move {
  0% {
    width: 0px;
  }
  100% {
    width: 100%;
  }
}

@keyframes logo-up {
  0% {
    height: 0px;
  }
  100% {
    height: 100%;
  }
}
